/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { getUserDetails } from './actions/userActions';
import AppLoader from './components/utils/skeletons.jsx/AppLoader';
import AvailabilityScreen from './screens/availability';
import ChangePassword from './screens/ChangePassword';
import BidRequestGroup from './screens/bid-request/group/[token]';
import BidRequestSet from './screens/bid-request/set';
import LoginScreen from './screens/LoginScreen';
import MessagesScreen from './screens/MessagesScreen';
import NotFoundPage from './screens/NotFound';
import TripRequestListScreen from './screens/TripRequestListScreen';

function App() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetailsData = useSelector((state) => state.userDetails);
  const { loading, userDetails } = userDetailsData;

  useEffect(() => {
    if (!userInfo || userDetails !== undefined) return;
    dispatch(getUserDetails());
  }, [userInfo, userDetails]);

  useEffect(() => {
    console.log('app userDetails ', userDetails);
  }, [userDetails]);
  if (loading) return <AppLoader />;
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />

        <Route path="/" element={userInfo ? <TripRequestListScreen exact /> : <Navigate to="/login" />} />
        <Route path="/bid-request/set" element={userInfo ? <BidRequestSet exact /> : <Navigate to="/login" />} />
        <Route path="/admin" element={userInfo ? <MessagesScreen exact /> : <Navigate to="/login" />} />
        <Route path="/availabilities" element={userInfo ? <AvailabilityScreen exact /> : <Navigate to="/login" />} />
        <Route path="/change-password" element={userInfo ? <ChangePassword exact /> : <Navigate to="/login" />} />

        <Route path="/bid-request/group/:token" element={<BidRequestGroup />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
