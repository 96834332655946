// Determine BASE_URL based on the environment
let baseUrl;

const environment = process.env.REACT_APP_ENVIRONMENT;

if (environment === 'LOCAL') {
  baseUrl = 'https://api.staging.trebu.io';
} else if (environment === 'DEVELOPMENT') {
  baseUrl = 'https://api.dev.trebu.io';
} else if (environment === 'STAGING') {
  baseUrl = 'https://api.staging.trebu.io';
} else if (environment === 'PRODUCTION') {
  baseUrl = 'https://api.trebu.io';
}

export const BASE_URL = baseUrl;
