/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { Box } from '@chakra-ui/react';

import { cleanAvailabilitiesMessages, deleteAvailability } from '../actions/availabilityActions';
import { createNewTrip } from '../actions/createTripAction';
import AvailabilitiesRequest from '../components/availability/AvailabilitiesRequest';
import Header from '../components/availability/Header';
import TableAvailability from '../components/availability/TableAvailability';
import SureModal from '../components/SureModal';
import TripRequestPaginator from '../components/TripRequestPaginator';
import { formattedData } from '../components/TripTableDateSelect';
import AvailabilityScrollableChatModal from '../components/utils/modals/AvailabilityScrollableChatModal';
import AvailabilityStatusesModal from '../components/utils/modals/AvailabilityStatusesModal';
import CreateTripModal from '../components/utils/modals/CreateTripModal';
import DetailsMessagesAvailabilityModal from '../components/utils/modals/DetailsMessagesAvailabilityModal';
import { cleanContactsDetails, cleanListContacts } from '../actions/clientActions';

const AvialabilityTemplate = ({
  availabilities,
  handleReadStatusToggle,
  readStatus,
  disabledButtons,
  setSelectedOption,
  setSelectedOptions,
  filters,
  setWithMaxScreen,
  withMaxScreen,
  pagination,
  setTablePage,
  elementShow,
  setElementShow,
  error,
  loading,
  refreshAvailabilities,
  currentId,
  setCurrentId,
  tablePage,
  selectedFilters,
  sortConfig,
  handleColumnSort,
  selectedOptions,
  is_staff,
}) => {
  const dispatch = useDispatch();
  const { loading: isDeleting } = useSelector((state) => state.availabilityDelete);

  const [data, setData] = useState(availabilities);
  const [showMessage, setShowMessage] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showChangeStatuses, setShowChangeStatuses] = useState(false);
  const [showSureModal, setShowSureModal] = useState({
    id: null,
    show: false,
  });
  useEffect(() => {
    if (error) return;
    setData(availabilities);
  }, [error, availabilities]);

  useEffect(() => {
    if (error === 'Invalid page.') {
      setTablePage(1);
    }
  }, [error]);

  //? refresh
  const TIMER_MS = 10000;
  useEffect(() => {
    if (error || loading || showDetail || showMessage || showSureModal.show) return;

    const interval = setInterval(() => {
      refreshAvailabilities();
    }, TIMER_MS);

    return () => clearInterval(interval);
  }, [
    tablePage,
    selectedFilters,
    error,
    elementShow,
    loading,
    showDetail,
    showMessage,
    showSureModal,
    refreshAvailabilities,
    sortConfig,
  ]);

  const onCloseDetailMessage = () => {
    setShowMessage(false);
    setCurrentId(null);
  };
  const deleteHandler = () => {
    dispatch(deleteAvailability(showSureModal.id)).then(() => {
      refreshAvailabilities();
      setShowSureModal({ id: null, show: false });
      onCloseDetailModal();
    });
  };
  const openDetailMessage = (id) => {
    setCurrentId(id);
    setShowMessage(true);
  };
  const openSureModal = (id) => {
    setShowSureModal({
      id,
      show: true,
    });
  };
  const openStatusesModal = (id) => {
    setCurrentId(id);
    setShowChangeStatuses(true);
  };
  const openDetailModal = async (id) => {
    await dispatch(cleanAvailabilitiesMessages());
    setCurrentId(id);
    setShowDetail(true);
  };
  const onCloseDetailModal = async () => {
    setShowDetail(false);
    setCurrentId(null);
    await dispatch(cleanAvailabilitiesMessages());
  };
  const handleSelectChange = (columnId, selectedOption, endDate) => {
    const columFilterDictionary = {
      offered_at: 'offered_at',
      start_date: 'start_date',
    };
    setSelectedOption(selectedOption);
    if (selectedOption === null) {
      // Remove the filter from the columnFilters state
      setSelectedOptions((prevSelectedOptions) => {
        const { [columFilterDictionary[columnId]]: _, ...rest } = prevSelectedOptions;
        return rest;
      });
    } else {
      if (columnId === 'offered_at') {
        setSelectedOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          offered_at_after: selectedOption ? formattedData(selectedOption) : selectedOption,
          offered_at_before: endDate ? formattedData(endDate) : endDate,
        }));
      } else if (columnId === 'start_date') {
        setSelectedOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          start_date_after: selectedOption ? formattedData(selectedOption) : selectedOption,
          start_date_before: endDate ? formattedData(endDate) : endDate,
        }));
      } else {
        setSelectedOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          [columFilterDictionary[columnId]]: selectedOption,
        }));
      }
    }
  };
  const handleSearchLocation = (values) => {
    const columFilterDictionary = {
      origin: 'origin',
      destination: 'destination',
      carrier_id: 'carrier_id',
      unit_type: 'unit_type',
      created_at: 'created_at',
      start_date: 'start_date',
    };

    Object.keys(values).forEach((columnId) => {
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [columFilterDictionary[columnId]]: values[columnId],
      }));
    });
  };

  const fetchNextPage = () => {
    setTablePage(pagination.currentPage + 1);
  };
  const fetchPreviousPage = () => {
    setTablePage(pagination.currentPage - 1);
  };

  const [openCreateTrip, setOpenCreateTrip] = useState(false);
  const handleCreateTrip = () => {
    setOpenCreateTrip(!openCreateTrip);
  };
  const handleCreateNewTrip = async (data) => {
    const params = {
      ...data,
      origin_location: data?.origin_location ?? '',
      destination_location: data?.destination_location ?? '',
      unit_type: data?.unit_type ?? '',
      offered_by: data?.offered_by ?? '',
      channel: data?.channel ?? '',
      start_date: data?.start_date ? formattedData(data.start_date) : '',
      start_time: data?.start_time ? moment(data.start_time).utcOffset(-6, true).format('HH:mm') : '',
      message: data?.message ?? '',
    };
    const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
    try {
      await dispatch(createNewTrip(filteredParams, 'trip-availabilities'));
      setOpenCreateTrip(false);
      dispatch(cleanContactsDetails());
      dispatch(cleanListContacts());
      refreshAvailabilities();
    } catch (error) {
      console.error('Error creating trip:', error);
    }
  };
  return (
    <Box position="relative" p={0} m={0} w={{ xl: withMaxScreen }}>
      <Header
        handleSelectChange={handleSelectChange}
        selectedOptions={selectedOptions}
        filters={filters}
        withMaxScreen={withMaxScreen}
        handleCreateTrip={handleCreateTrip}
        handleSearchLocation={handleSearchLocation}
      />
      <TableAvailability
        setWithMaxScreen={setWithMaxScreen}
        withMaxScreen={withMaxScreen}
        setData={setData}
        data={data}
        elementShow={elementShow}
        loading={loading}
        readStatus={readStatus}
        openDetailMessage={openDetailMessage}
        openSureModal={openSureModal}
        showMessage={showMessage}
        disabledButtons={disabledButtons}
        handleReadStatusToggle={handleReadStatusToggle}
        openStatusesModal={openStatusesModal}
        openDetailModal={openDetailModal}
        sortConfig={sortConfig}
        handleColumnSort={handleColumnSort}
        is_staff={is_staff}
      />
      <AvailabilitiesRequest
        setWithMaxScreen={setWithMaxScreen}
        withMaxScreen={withMaxScreen}
        setData={setData}
        data={data}
        elementShow={elementShow}
        loading={loading}
        readStatus={readStatus}
        openDetailMessage={openDetailMessage}
        openSureModal={openSureModal}
        showMessage={showMessage}
        disabledButtons={disabledButtons}
        handleReadStatusToggle={handleReadStatusToggle}
        openStatusesModal={openStatusesModal}
        openDetailModal={openDetailModal}
        sortConfig={sortConfig}
        handleColumnSort={handleColumnSort}
        openChatModal={openDetailMessage}
      />
      {data.length > 0 && (
        <TripRequestPaginator
          pagination={pagination}
          fetchNextPage={fetchNextPage}
          fetchPreviousPage={fetchPreviousPage}
          elementShow={elementShow}
          setElementShow={setElementShow}
          withMaxScreen={withMaxScreen}
          setTablePage={setTablePage}
        />
      )}
      <AvailabilityScrollableChatModal id={currentId} isOpen={showMessage} onClose={onCloseDetailMessage} />
      <SureModal
        show={showSureModal?.show}
        id={showSureModal?.id}
        onHide={() =>
          setShowSureModal({
            id: null,
            show: false,
          })
        }
        onConfirm={deleteHandler}
        isLoading={isDeleting}
      />

      <AvailabilityStatusesModal
        id={currentId}
        isOpen={showChangeStatuses}
        onClose={() => {
          setShowChangeStatuses(false);
        }}
      />

      <DetailsMessagesAvailabilityModal
        currentTripId={currentId}
        isOpen={showDetail}
        onClose={onCloseDetailModal}
        refreshAvailabilities={refreshAvailabilities}
        openSureModal={openSureModal}
      />

      <CreateTripModal
        isOpen={openCreateTrip}
        onClose={handleCreateTrip}
        onSubmit={handleCreateNewTrip}
        isAvailability
      />
    </Box>
  );
};

export default AvialabilityTemplate;
