import React from 'react';
// import { FiFilter } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import { Box, Flex, Heading } from '@chakra-ui/react';

import AvailabilitiesFilters from '../tripRequest/utils/AvailabilitiesFilters';
import Buttom from '../utils/buttons/Buttom';

const Header = ({
  handleSelectChange,
  filters,
  withMaxScreen,
  handleSearchLocation,
  selectedOptions,
  handleCreateTrip,
}) => {
  const { unitTypes } = useSelector((state) => state.unitTypeDropdownList);
  const { clients } = useSelector((state) => state.clientDropdownList);
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  return (
    <Box mt={{ base: 3, xl: 5 }} pt={2}>
      <Box display={{ base: 'flex' }} justifyContent={'space-between'}>
        <Heading
          as="h1"
          size={{ base: 'sm', md: 'md' }}
          pt={2}
          pl={2}
          color="gray.700"
          display={{ base: 'block', xl: 'hidden' }}
        >
          Disponibilidades
        </Heading>
        {userDetails?.is_staff && (
          <Buttom
            onClick={handleCreateTrip}
            text="Crear solicitud"
            color={'orange.500'}
            background="blue.400"
            size="sm"
            borderRadius="xl"
            p={2}
            m={0}
            mt={2}
            display={{ base: 'flex', xl: 'none' }}
            w="100%"
          />
        )}
      </Box>

      <AvailabilitiesFilters
        handleSelectChange={handleSelectChange}
        clients={clients}
        unitTypes={unitTypes}
        filters={filters}
        withMaxScreen={withMaxScreen}
        handleSearchLocation={handleSearchLocation}
        selectedOptions={selectedOptions}
      />
    </Box>
  );
};

export default Header;
